import { io } from "socket.io-client";
import SimplePeer from "simple-peer";
import store from "../store/index";


    var socket = {};

export default{
    initiated: false,
    init(){
           
            socket = io(process.env.BACK_END_URL, {
                query: {
                    userId: store.state.user.userId,
                    authToken: store.state.authtoken
                }
            });
            this.initiated = true;

            socket.on("chat-history", (messages) => {
                //this.chatMessages = messages;
            });
        
            // Listen for new messages
            socket.on("receive-message", (message) => {
                debugger;
                store.dispatch("addMessageToRoom", {message});
            });

            socket.on("joined-room", (room) => {
                store.dispatch("addRoom", {room});
            });

            socket.on("update-room-meta", (room) => {
  
                store.dispatch("updateRoom", room);
            });

            socket.on('user-updated', (user) => {
                
                store.dispatch("updateUser", user);
            });

            socket.on('update-room-invites', (invites) => {
                store.dispatch("updateRoomInvites", invites);
            });

            socket.on('update-friend-requests', (requests) => {
                
                store.dispatch("updateFriendRequests", requests);
            });

            socket.on("room-added", (room) => {
                store.dispatch("addedNewRoom", room);
            })
        

    },
    acceptInvite(roomId, userId){
        if(roomId && userId){
            socket.emit('accept-invite', {roomId : roomId, userId : userId});
        }
    },
    declineInvite(roomId, userId){
        if(roomId && userId){
            socket.emit('decline-invite', {roomId : roomId, userId : userId});
        }
    },
    acceptFriendRequest(userId, friendId){
        if(userId && friendId){
            socket.emit("accept-friend-request", {userId : userId, friendId: friendId});
        }
    },
    seeFriendRequest(userId, friendId){
        if(userId && friendId){
            socket.emit("see-friend-request", {userId: userId, friendId: friendId});
        }
    },
    seeRoomInvite(roomId, userId){
        if(roomId && userId){
            socket.emit("see-room-invite", {roomId: roomId, userId: userId});
        }
    },
    updateUserMeta(key, value){
        
        if(key && (value || value == 0)){
            socket.emit("update-user-meta", {userId : store.state.user.userId, key : key, value : value});
        }
    },
    closeRoom(roomId){
        if(roomId){
            socket.emit("close-room", roomId);
        }
    },
    createRoom(displayName, description ){
        if(displayName){
            socket.emit("create-room", {displayName : displayName, description: description, userId : store.state.user.userId});
        }
    },

    joinRoom(roomId){
        if(roomId){
            socket.emit("join-room", {roomId : roomId, userId: store.state.user.userId });
        }
    },

    sendMessage(messageData){
        if(messageData.roomId && messageData.message){
            socket.emit("send-message", { roomId: messageData.roomId, userId : store.state.user.userId, parentMessage : messageData.parentMessage, message : messageData.message})
        }
    },
    updateRoomMusic(roomId, musicData){
        
        if(musicData){
            socket.emit("update-room-music", {roomId : roomId, musicData : musicData});
        }
    }
}