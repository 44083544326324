<template>
<div class="chat-invite-container">
    <ChatMusic v-if="musicSelectionVisible" @submit="updateChatMusic" @close="closeChatMusic"></ChatMusic>
    <div class="chat-invite-modal">

        <div class="chat-invite-title">Invite To Chat</div>
        <div class="chat-invite-rooms-container">
            <div v-show="!isSingleRoom" class="room-controls">
                <select class="room-control-select" v-model="roomSelectionType">
                    <option value="new">New Room</option>
                    <option value="search">Search Active Rooms</option>
                </select>
            </div>
            <div v-show="!isSingleRoom && roomSelectionType=='new'" class="create-room-container">
                <input class="add-room-input" v-model="newRoomData.displayName" type="text" placeholder="Name" maxlength="50"/>
                <input class="add-room-input" v-model="newRoomData.description" type="text" placeholder="Description" maxlength="300"/>
                <select class="add-room-input" v-model="newRoomData.accessibility" >
                    <option>public</option>
                    <option>private</option>
                </select>   
                <input class="add-room-input" @change="onFileUpload" name="img" type="file" accept=".png, .jpg, .jpeg, .webp" placeholder="Room Image"/>
                <div class="add-room-label">{{ 'Media : ' + (newRoomData.mediaData.source || 'none') }}</div>
                <div class="add-room-btn" v-on:click="openChatMusic" >Room Media</div>
                <div class="add-room-btn" v-on:click="selectNewRoom" > Select </div>
            </div>
            
            <div v-show="roomSelectionType!='new'" class="selected-users-label">Selected Room : </div>
            <div v-show="roomSelectionType!='new' && !!selectedRoom.displayName" class="selected-room">
                <div class="selected-room-img item-avatar" :style="{backgroundImage: `url(${roomImageBaseUrl}${selectedRoom.roomId}.webp)`}"></div>
                <div class="selected-room-name">{{ selectedRoom.displayName }}</div>
                <div class="selected-room-accessibility">{{ ' - ' + selectedRoom.accessibility }}</div>
            </div>
            <div v-show="!isSingleRoom && roomSelectionType=='search'" class="room-search-container">
                <div class="room-search">
                    <input class="rooms-search-input" placeholder="Search Rooms" type="text" v-model="searchQuery" @input="queryUpdated"/>
                </div>
                <div class="rooms-search-results">
                    <div v-for="room in roomSearchResults" :key="room.roomId" :class="{selected: isSelectedRoom(room.roomId)}" class="room-item users-results-item">
                        <div class="room-img item-avatar" :style="{backgroundImage: `url(${roomImageBaseUrl}${room.roomId}.webp)`}"></div>
                        <div class="room-label">{{ room.displayName }}</div>
                        <div class="room-select-btn room-invite-btn" v-on:click="selectRoom(room.roomId)">select</div>
                    </div>
                </div>
            </div>


        </div>
        <div class="chat-invite-users-container">
            <div v-show="!isSingleUser" class="users-search">
                <input class="user-search-input" placeholder="Search Users" type="text" v-model="usersSearchQuery" @input="userQueryUpdated"/>
            </div>
            <div v-show="!isSingleUser" class="users-search-results">
                <div v-for="user in userResults" :key="user.userId" class="users-results-item">
                    <div class="user-results-item-avatar" :style="{backgroundImage: `url(${avatarBaseUrl}${(user.avatar == 'custom' ? user.userId : 'default1')}.webp)`}"></div>
                    <div class="user-results-item-name">{{ user.displayName }}</div>
                    <div v-on:click="selectUser(user.userId)" class="select-user-btn add-room-btn">Select</div>
                </div>
            </div>
            <div class="selected-users-label">Selected Users : </div>
            <div class="selected-users">
                <div class="selected-user" v-for="user in selectedUsers" :key="user.userId">
                    <div class="user-avatar " :style="{backgroundImage: `url(${avatarBaseUrl}${(user.avatar == 'custom' ? user.userId : 'default1')}.webp)`}"></div>
                    <div class="user-name">{{ user.displayName }}</div>
                    <div v-on:click="removeSelectedUser(user.userId)" class="remove-user-btn">
                        <i class="fa-solid fa-times"></i>
                    </div>
                </div>
            </div>
            
        </div>

        <div class="invite-controls">
            <div v-on:click="submit" class="room-invite-btn send-invite-btn">Send Invite(s)</div>
            <div v-on:click="cancel" class="room-invite-btn cancel-invite-btn">Cancel</div>
        </div>
    </div>

</div>
</template>

<script>

import socialModule from '../../module/social';
import api from '../../api/app';
import { mapState } from 'vuex';
import ChatMusic from '../ChatMusic.vue'

    export default{
        props: {
        currentRoom: Object,
        currentUser: Object
    },
    
    components: {
        ChatMusic
    },
    
    data() {
      return {
        selectedRoom: {},
        selectedUsers: [],
        roomSelectionType: "search",
        newRoomData: {
            displayName : "",
            description : "",
            imgFile : null,
            accessibility : "public",
            mediaData : {
                source : "",
                id : "",
                displayName : "",
                streamUrl : "",
                country: ""
            }
        },
        musicSelectionVisible:false,
        searchQuery: '',
        usersSearchQuery: '',
        roomSearchResults: [],
        rawUserResults: [],
        avatarBaseUrl: process.env.VUE_APP_AVATAR_BASE_URL,
        roomImageBaseUrl: process.env.VUE_APP_ROOM_IMAGE_BASE_URL

      }
    },
    mounted: function(){
        if(this.currentUser){
            this.selectedUsers.push(this.currentUser);
        }
        if(this.currentRoom){
            this.selectedRoom = this.currentRoom;
        }
    },
    computed: {
        isSingleRoom(){
            return !!this.currentRoom;
        },
        isSingleUser(){
            return !!this.currentUser;
        },
        userResults(){
            return this.rawUserResults.filter(rawUser => !this.selectedUsers.some(selectedUser => selectedUser.userId == rawUser.userId));
        }
    },
    methods: {
        submit(){
            if(this.selectedUsers && this.selectedUsers.length > 0 && this.selectedRoom && this.selectedRoom.roomId){
                var newRoom = (this.roomSelectionType == 'new');
                this.$emit('submit', { users: this.selectedUsers, room: this.selectedRoom, newRoom: newRoom, newRoomData: this.newRoomData });
            }
        },
        cancel(){
            this.$emit('close');
        },
        isSelectedRoom(roomId){
            return roomId == this.selectedRoom.roomId;
        },
        
        selectUser(userId){
            var newSelection = this.userResults.filter(x => x.userId == userId)[0];
            this.selectedUsers.push({...newSelection});
            
        },
        removeSelectedUser(userId){            
            this.selectedUsers = this.selectedUsers.filter(x => x.userId != userId);
        },
        selectNewRoom(){            
            if(this.newRoomData.displayName){
                this.selectedRoom = {...this.newRoomData};
            }
        },
        selectRoom(roomId){
            var newSelection = this.roomSearchResults.filter(x => x.roomId == roomId)[0];
            this.selectedRoom = {...newSelection};
        },
        userQueryUpdated(){
                var self = this;
                clearTimeout(this.debounceTimer);

                this.debounceTimer = setTimeout(() => {
                    console.log('doing debouncer stuff');
                    if(!this.usersSearchQuery){
                        self.rawUserResults = [];
                        return;
                    }
                    api.userSearch(this.$store.state.user.userId, self.usersSearchQuery, 1).then((response) => {
                        
                        if(response.data){
                            
                            self.rawUserResults = response.data;
                        }
                        
                    }).catch((e) => {
                        console.error(e);
                    })
                }, 300);
            },
        queryUpdated(){
            var self = this;
            clearTimeout(this.debounceTimer);

            this.debounceTimer = setTimeout(() => {
                console.log('doing debouncer stuff');
                if(!this.searchQuery){
                    self.roomSearchResults = [];
                    return;
                }
                api.roomSearch(this.$store.state.user.userId, self.searchQuery, 1).then((response) => {
                    if(response.data){
                        
                        self.roomSearchResults = response.data;
                    }
                }).catch((e) => {
                    console.error(e);
                })
            }, 300);
        },
        openChatMusic(){
            this.musicSelectionVisible = true;
        },
        updateChatMusic(data){

            this.newRoomData.mediaData = {
                source : data.source,
                id : data.id,
                displayName : data.displayName,
                streamUrl : data.streamUrl,
                country: data.country
            };
            this.musicSelectionVisible = false;
        },
        closeChatMusic(){
            this.musicSelectionVisible = false;
        },
        onFileUpload(event){
            this.newRoomData.imgFile = event.target.files[0];
        },
    }
    }
</script>

<style scoped>
.chat-invite-container{
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    display:flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
}

.chat-invite-modal{
    max-height:100%;
    width: 30em;
    display:flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: .5em;
    padding-top:2.5em;
    gap: .5em;
    background-color: #444;
    border-radius:.3em;
    position:relative;
}

.chat-invite-title{
    font-weight:bold;
    color:white;
    font-size: 1.3em;
    width:100%;
    text-align:left;
    position:absolute;
    top:0;
    left:0;
    background-color:#222;
    box-sizing:border-box;
    padding:.3em;
}

.chat-invite-rooms-container{
    width: 100%;
    display:flex;
    flex-direction: column;
    gap:1em;
    color:white;
}

.room-controls{
    width:100%;
    display:flex;
    flex-direction: row;

}

.create-room-container{
    width: 100%;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    gap: 1em;
}

.selected-room{
    padding:.3em;
    margin-right:auto;
    display:flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    gap: .3em;
    background-color: black;
    border-radius:.2em;
}

.chat-invite-users-container{
    width:100%;
    display:flex;
    flex-direction:column;
    gap:1em;
}

.selected-users{
    width:100%;
    display:flex;
    flex-direction:row;
    gap:1em;
    flex-wrap:wrap;
}
.selected-users-label{
    font-size: .9em;
    width: 100%;
    text-align:left;
    margin-top:1em;
    font-weight:bold;
    color:white;
}
.selected-user{
    display:flex;
    flex-direction: row;
    align-items:center;
    font-weight:bold;
    gap:.3em;
    background-color:#337;
    border-radius: .5em;
    padding: .2em;
    color:white;
}
.select-user-btn{
    margin-left:auto;
}
.remove-user-btn{
    border-radius: 100%;
    width: 1em;
    height: 1em;
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;
    margin-left:.2em;
    font-size:.8em;
}

.room-search-container{
    display:flex;
    flex-direction: column;
    gap: 1em;
}

.users-search{
    display:flex;
    margin-top:2em;
    width:100%;
    justify-content:start;
}

.user-search-input{
    border-radius: .3em;
}

.users-search-results{
    width:100%;
    max-height:5em;
    display: flex;
    flex-direction:column;
    overflow-y:scroll;
    overflow-x:hidden;

}

.rooms-search-results{
    width:100%;
    max-height:5em;
    display: flex;
    flex-direction:column;
    overflow-y:scroll;
    overflow-x:hidden;
}

.chat-list-item{
    display:flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    border-bottom: 1px #333 solid;
    cursor:pointer;
    padding: 2em;
    transition: all 100ms ease-in-out;
}

.chat-list-item:hover{
    background-color:#222;
    transition: all 100ms ease-in-out;
}

.invite-controls{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    gap: 2em;
    margin-top:1em;
    margin-bottom:1em;
}

.add-room-input{
    border-radius: .3em;
    height: 2em;
}

.room-select-btn{
    margin-left:auto;
}

.room-invite-btn{
    background-color:black;
    color:white;
    font-weight:bold;
    border-radius: .3em;
    border : 1px solid white;
    padding:1em;
    height:2em;
    display:flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    cursor:pointer;
    transition: all 100ms ease-in-out;
}

.room-invite-btn:hover{
    background-color:#555;
    transition: all 100ms ease-in-out;
}

.add-room-btn{
    background-color:black;
    color:white;
    font-weight:bold;
    border-radius: .3em;
    border : 1px solid white;
    padding:1em;
    height:2em;
    display:flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    transition: all 100ms ease-in-out;
    cursor: pointer;
}

.add-room-btn:hover{
    background-color:#555;
    transition: all 100ms ease-in-out;
}

.users-results-item{
    width:100%;
    flex: 0 0 .4em;
    padding:.4em;
    display:flex;
    flex-direction: row;
    align-items: center;
    background-color: black;
    transition: all 200ms ease-in-out;
    gap: 1em;
    box-sizing: border-box;
}

.users-results-item:hover{
    background-color: rgb(255, 255, 255, .1);
    transition: all 200ms ease-in-out;
}
.user-avatar{
    border-radius: 100%;
    width: 1.5em;
    height: 1.5em;
    background-repeat:no-repeat;
    background-position: center;
    background-size:cover;
    cursor:pointer;
}

.item-avatar{
    border-radius: 100%;
    width: 2.5em;
    height: 2.5em;
    background-repeat:no-repeat;
    background-position: center;
    background-size:cover;
    cursor:pointer;
}

.selected-room-name{
    font-weight:bold;
}

.selected-room-accessibility{
    font-style:italic;
    font-size: .7em;
}

.selected-room-img{
    border-radius: .2em;
    width: 1.5em;
    height: 1.5em;
    background-repeat:no-repeat;
    background-position: center;
    background-size:cover;
    cursor:pointer;  
}

.user-results-item-avatar{
    border-radius: 100%;
    width: 2.5em;
    height: 2.5em;
    background-repeat:no-repeat;
    background-position: center;
    background-size:cover;
    cursor:pointer;
}

.user-results-item-name{
    font-weight: bold;
    font-size: 1.2em;
    color:white;
}
</style>