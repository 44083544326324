import { Connection, Keypair, LAMPORTS_PER_SOL, PublicKey, clusterApiUrl } from "@solana/web3.js";

import { useWallet } from 'solana-wallets-vue-2-jc';

export default {
    getSolanaBalance: async function(walletAddress){
        const connection = new Connection('https://solana-mainnet.core.chainstack.com/3567ffea11742004db4fa6b35b7d0595');
        const pKey = new PublicKey(walletAddress);
        var balance = await connection.getBalance(pKey);

        return balance/LAMPORTS_PER_SOL;
    }

}