<template>
    <div class="rooms-container social-view-container">

        <!--<ConversationView @close="closeRoom" v-bind="{id : currentRoomId, socialModule: sModule}" v-if="chatVisible"></ConversationView>-->
        <ChatMusic v-if="musicSelectionVisible" @submit="updateChatMusic" @close="closeChatMusic"></ChatMusic>
        <div class="rooms-search-container">
            <input class="rooms-search-input" placeholder="Search Rooms" type="text" v-model="searchQuery" @input="queryUpdated"/>
        </div>


        <div v-show="showSearchResults" class="search-results-container">
            <div v-on:click="openRoom(room.room)" v-for="room in roomSearchResults" :key="room.roomId" class="room-item chat-list-item">
                <div class="room-label">{{ room.displayName }}</div>
                <div class="room-join-btn" v-on:click="joinRoom(room.roomId)">Join</div>
            </div>
        </div>


        <div v-show="!showSearchResults" class="chat-list-item new-room-btn">
            <div class="add-room-background" v-show="!addingRoom" v-on:click="startAddingRoom"></div>
            <div v-show="!addingRoom"><i  class="fa-solid fa-plus"></i></div>
            <div v-if="!addingRoom" class="new-room-text">Add New Chat Room</div>
            <input class="add-room-input" v-if="addingRoom" v-model="newRoomData.displayName" type="text" placeholder="Name"  maxlength="50"/>
            <input class="add-room-input" v-if="addingRoom" v-model="newRoomData.description" type="text" placeholder="Description" maxlength="300"/>
            <select class="add-room-input" v-if="addingRoom" v-model="newRoomData.accessibility" >
                <option>public</option>
                <option>private</option>
            </select>   
            <input class="add-room-input" v-if="addingRoom" @change="onFileUpload" name="img" type="file" accept=".png, .jpg, .jpeg, .webp" placeholder="Room Image"/>
            <div v-if="addingRoom" class="add-room-label">{{ 'Media : ' + (newRoomData.mediaData.source || 'none') }}</div>
            <div class="add-room-btn" v-on:click="openChatMusic" v-if="addingRoom">Room Media</div>
            <div class="add-room-btn" v-on:click="addNewRoom" v-if="addingRoom"> Add </div>
            <div class="add-room-btn" v-on:click="cancelNewRoom" v-if="addingRoom"> Cancel </div>
        </div>

        <div v-show="!showSearchResults" class="room-lists">
            <div class="invites-label section-label">Invites</div>
            <div v-if="!invites || invites.length < 1" class="none-message">No invites found</div>
            <div class="invites-list-container">
                <div v-for="invite in invites" :key="invite.roomId" class="room-item chat-list-item">
                    <div class="room-label">{{ invite.room.displayName }}</div>
                    <div class="room-join-btn" v-on:click="acceptInvite(invite.roomId)">Accept</div>
                    <div class="room-join-btn" v-on:click="declineInvite(invite.roomId)">Decline</div>
                </div>
            </div>
            <div class="active-chat-label section-label">Active</div>
            <div v-if="!rooms || rooms.length < 1" class="none-message">No active rooms found</div>
            <div class="chat-list-container">
                <div v-on:click="openRoom(room.room)" v-for="room in rooms" :key="room.roomId" class="room-item chat-list-item">
                    <div class="room-label">{{ room.displayName }}</div>
                    <div class="room-join-btn" v-on:click="joinRoom(room.roomId)">Join</div>
                </div>
                
            </div>
        </div>

    </div>
</template>

<script>
    import isModule from '../module/social';
    import api from '../api/app';
    import { mapState } from 'vuex';
    import wallet from '../module/wallet';
    import ConversationView from '../views/social/ConversationView';
    import ChatMusic from './ChatMusic.vue'

    export default {
        components: {
            ChatMusic
        },
        props:{
            sModule: Object
        },
        data() {
            return {
                showSearchResults: false,
                searchQuery: "",
                debounceTimer: null,
                roomSearchResults: [],
                avatarBaseUrl: process.env.VUE_APP_AVATAR_BASE_URL,
                chatVisible: false,
                activeRooms: [],
                addingRoom: false,
                currentRoomId : "",
                newRoomName : "",
                newRoomDescription: "",
                loadingRooms: false,
                roomsLoaded: false,
                invites: [],
                musicSelectionVisible: false,
                socialModule: {},
                newRoomData: {
                    displayName : "",
                    description : "",
                    imgFile : null,
                    accessibility : "public",
                    mediaData : {
                        source : "",
                        id : "",
                        displayName : "",
                        streamUrl : "",
                        country: ""
                    }


                }


            }
        },
        mounted: async function(){

            if(this.sModule){
                this.socialModule = this.sModule;
            }
            else{
                this.socialModule = isModule;
            }
            
            if(!this.socialModule.initiated){
                this.socialModule.init();
            } 

            if(this.$store.state.user){
        
                var self = this;
                if(!this.roomsLoaded){
                    this.loadingRooms = true;
                    

                    api.getUserRooms(this.$store.state.user.userId).then(function(response) {
                        var userWithRooms = response.data;
                        var roomList = userWithRooms.Rooms;
                        self.$store.dispatch("loadRooms", roomList);
                        self.invites = self.roomInvites;
                        self.loadingRooms = false;
                        self.roomsLoaded = true;
                    }).catch(function(error){
                        self.loadingRooms = false;
                        console.log(error);
                    });
                }
          }
          this.activeRooms = Object.values(this.$store.state.rooms);
          var reqs = this.$store.state.roominvites;
            reqs.forEach(element => {
                if(!element.seen){
                    this.socialModule.seeRoomInvite(element.roomId, this.$store.state.user.userId);
                }
            });
          
        
        },
        computed: {
            getClientId: function(){
                return process.env.VUE_APP_CLIENT_ID;
            },
            ...mapState(['rooms']),
            ...mapState(['user']),
            roomInvites: function(){
                return this.$store.getters.roomInvites;
            }
            
        },
        watch: {
            user(){
                if(this.$store.state.user){
                    var self = this;
                    if(!this.roomsLoaded){
                        this.loadingRooms = true
                        api.getUserRooms(this.$store.state.user.userId).then(function(response) {
                            var userWithRooms = response.data;
                            var roomList = userWithRooms.Rooms;
                            self.$store.dispatch("loadRooms", roomList);
                            self.loadingRooms = false;
                            self.roomsLoaded = true;
                        }).catch(function(error){
                            self.loadingRooms = false;
                            console.log(error);
                        });
                    }
                }
            }
            
                
            
        },
        methods: {
            acceptInvite(invite){
                this.socialModule.acceptInvite(invite, this.$store.state.user.userId);
            },
            declineInvite(invite){
                this.socialModule.declineInvite(invite, this.$store.state.user.userId);
            },
            openChatMusic(){
                this.musicSelectionVisible = true;
            },
            updateChatMusic(data){

                if(data.source == "radiobrowser"){
                    this.newRoomData.mediaData = {
                        source : data.source,
                        id : data.id,
                        displayName : data.displayName,
                        streamUrl : data.streamUrl,
                        country: data.country
                    }
                }
                if(data.source.includes('youtube')){
                    this.newRoomData.mediaData = {
                        source : data.source,
                        url: data.url
                    }
                }
                this.musicSelectionVisible = false;
            },
            closeChatMusic(){
                this.musicSelectionVisible = false;
            },
            onFileUpload(event){
                this.newRoomData.imgFile = event.target.files[0];
            },
            queryUpdated(){
                var self = this;
                clearTimeout(this.debounceTimer);

                this.debounceTimer = setTimeout(() => {
                    console.log('doing debouncer stuff');
                    if(!this.searchQuery){
                        self.showSearchResults = false;
                        return;
                    }
                    api.roomSearch(this.$store.state.user.userId, self.searchQuery, 1).then((response) => {
                        if(response.data){
                            
                            self.roomSearchResults = response.data;
                            self.showSearchResults = true;
                        }
                    }).catch((e) => {
                        console.error(e);
                    })
                }, 300);
            },
            cancelNewRoom(){
                this.addingRoom = false;
            },
            openRoom(room){

            },
            startAddingRoom(){
                this.addingRoom = true;
            },
            addNewRoom(){
                
                if(!this.newRoomData.displayName){
                    return;
                    //TODO - error
                }
                
                var self = this;

                var roomData = {
                    displayName: this.newRoomData.displayName,
                    description: this.newRoomData.description,
                    accessibility: this.newRoomData.accessibility,
                    roomImg: this.newRoomData.imgFile,
                    mediaData: this.newRoomData.mediaData,
                    owner: this.$store.state.user.userId
                };

                api.createRoom(roomData).then(function(response) {
                    var newRoom = response.data;
                    this.socialModule.joinRoom(newRoom.roomId);
                    self.currentRoomId = newRoom.roomId;
                    self.closeAddingRoom();
                    //self.chatVisible = true;
                    self.$router.push({name: 'social_room', params : {id: newRoom.roomId}}).catch(()=>{});
                }).catch(function(error){
                    self.closeAddingRoom();
                    console.log(error);
                });
            },
            closeAddingRoom(){

                this.newRoomData = {
                    displayName : "",
                    description : "",
                    imgFile : null,
                    accessibility : "public",
                    mediaData : {
                        source : "",
                        id : "",
                        displayName : "",
                        streamUrl : "",
                        country: ""
                    }
                };

                self.addingRoom = false;
            },
            closeRoom(){
                this.chatVisible = false;
                this.socialModule.closeRoom(this.currentRoomId);
            },
            joinRoom(roomId){
                this.currentRoomId = roomId;            
                this.socialModule.joinRoom(roomId);
                this.$router.push({name: 'social_room', params : {id: roomId}}).catch(()=>{});
                //this.chatVisible = true;
            },
        }
    }

</script>

<style scoped>
    .rooms-container{
        gap:1em;
    }
    .none-message{
        font-size: .8em;
        color: rgb(255, 255, 255, .4);
        font-style: italic;
    }
    .rooms-search-container{
        flex: 0 0 3em;
        display:flex;
        flex-direction: row;
        width: 100%;
        gap: .4em;
        margin-top: 2em;
    }

    .search-results-container{
        width:100%;
        flex:1;
        overflow-y:scroll;
    }

    .rooms-search-input{
        width:100%;
        border-radius: 5em;
        font-size: 1em;
        padding-left:1em;
        padding-right:1em;
    }

    .room-lists{
        width:100%;
        display:flex;
        flex-direction:column;
        gap: 1em;
        box-sizing: border-box;
        overflow: hidden;
    }
    .new-room-btn{
        width:100%;
        flex-wrap:wrap;
        position:relative;
    }
    .add-room-background{
        position:absolute;
        width:100%;
        height:100%;
        top:0;
        left:0;
    }
    .invites-list-container{
        width:100%;
        display:flex;
        flex-direction: column;
        padding:2em;
        overflow-y:scroll;
    }

    .chat-list-container{
        flex:1;
        width:100%;
        display:flex;
        flex-direction: column;
        padding: 2em;
        overflow-y: scroll;
    }

    .section-label{
        width:100%;
        font-size: 1.2em;
        font-weight:bold;
        display:flex;
        align-items: center;
        justify-content: start;
        padding-left: 1em;
        box-sizing: border-box;
    }
    .room-label{

    }

    .room-join-btn{
        margin-right: 1em;
        margin-left: auto;
        font-weight: bold;
        padding: .4em;
        border-radius: .4em;
        border: 1px solid #555;
        cursor:pointer;

    }

    .room-join-btn:hover{
        background-color: rgb(255, 255, 255, .3);
    }

    .chat-list-item{
        display:flex;
        flex-direction: row;
        align-items: center;
        gap: 1em;
        border-bottom: 1px #333 solid;
        cursor:pointer;
        padding: 2em;
        transition: all 100ms ease-in-out;
    }

    .chat-list-item:hover{
        background-color:#222;
        transition: all 100ms ease-in-out;
    }

    .add-room-input{
        border-radius: .3em;
        height: 2em;
    }

    .add-room-btn{
        background-color:black;
        color:white;
        font-weight:bold;
        border-radius: .3em;
        border : 1px solid white;
        padding:1em;
        height:2em;
        display:flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        transition: all 100ms ease-in-out;
    }

    .add-room-btn:hover{
        background-color:#555;
        transition: all 100ms ease-in-out;
    }


</style>