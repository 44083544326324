<template>
    <div class="users-container social-view-container">
        <ChatInvite v-if="showChatInvite" @close="closeInvite" @submit="submitInvite" v-bind="{currentUser: invitedUser}"></ChatInvite>
        <div class="users-search-container">
            <input class="user-search-input" placeholder="Search Users" type="text" v-model="searchQuery" @input="queryUpdated"/>
        </div>
        <div v-if="!showSearchResults" class="user-friends-container">
            
            <div class="user-friends-title">Friend Requests</div>
            <div class="users-results-container">
                <div class="users-results-item" v-for="request in friendRequests" :key="request.userId">
                    <div class="user-results-item-avatar" :style="{backgroundImage: `url(${avatarBaseUrl}${request.userId}.webp)`}"></div>
                    <div class="user-results-item-name">{{ request.displayName }}</div>
                    <div v-on:click="acceptRequest(request)" class="user-results-invite-btn">Accept</div>
                </div>
            </div>
            <div class="user-friends-title">Friends</div>
            <div class="user-friends-list">
                <div v-for="friend in friends" :key="friend.userId" class="user-friends-item">
                    <div class="user-friends-item-avatar" :style="{backgroundImage: `url(${avatarBaseUrl}${friend.userId}.webp)`}"></div>
                    <div class="user-friends-item-name">{{ friend.displayName }}</div>
                    <div v-on:click="inviteToChat(friend)" class="user-friends-invite-btn">Invite</div>
                </div>
            </div>

        </div>
        <div v-if="showSearchResults" class="users-results-container">
            <div v-for="user in userResults" :key="user.userId" class="users-results-item">
                <div class="user-results-item-avatar" :style="{backgroundImage: `url(${avatarBaseUrl}${user.userId}.webp)`}"></div>
                <div class="user-results-item-name">{{ user.displayName }}</div>
                <div v-on:click="inviteToChat(user)" class="user-results-invite-btn">Invite</div>
                <!--Pending friend request indicator-->
                <div v-if="!isRequested(user.userId) && user.userId != $store.state.user.userId && (user.friendStatus == 'false' || user.friendStatus == 'declined')" v-on:click="addFriend(user.userId)" class="user-results-add-btn">Add Friend</div>
            </div>
        </div>
    </div>
</template>

<script>
    import isModule from '../module/social';
    import api from '../api/app';
    import { mapState } from 'vuex';
    import wallet from '../module/wallet';
    import ChatInvite from './socialmodals/ChatInvite';
    import ConversationView from '../views/social/ConversationView';

    export default {
        components: {
            ChatInvite
        },
        props:{
            sModule: Object
        },
        data() {
            return {
                showSearchResults: false,
                searchQuery: "",
                friendsLoading: true,
                debounceTimer: null,
                friends: [],
                userResults: [],
                avatarBaseUrl: process.env.VUE_APP_AVATAR_BASE_URL,
                requestedFriends: [],
                invitedUser: {},
                showChatInvite: false,
                socialModule: {}


            }
        },
        computed:{
            friendRequests: function(){
                return this.$store.state.friendrequests;
            }
        },
        mounted: async function(){
            var self = this;

            if(this.sModule){
                this.socialModule = this.sModule;
            }
            else{
                this.socialModule = isModule;
            }
            
            if(!this.socialModule.initiated){
                this.socialModule.init();
            } 

            api.getUserFriends(this.$store.state.user.userId).then((response) =>{
                if(response.data){
                    
                    self.friends = response.data;
                    self.friendsLoading = false;
                }
            }).catch((e) => {
                console.error(e);
                self.friendsLoading = false;
            });
        
            var reqs = this.$store.state.friendrequests;
            reqs.forEach(element => {
                if(!element.seen){
                    this.socialModule.seeFriendRequest(element.userId, this.$store.state.user.userId);
                }
            });
        },
        methods: {
            acceptRequest(sender){
                this.socialModule.acceptFriendRequest(sender.userId, this.$store.state.user.userId);
            },
            closeInvite(){
                this.showChatInvite = false;
            },
            submitInvite(inviteResult){
                var self = this;
                if(inviteResult.newRoom){
                    var newRoomData = inviteResult.selectedRoom;
                    var roomData = {
                        displayName: newRoomData.displayName,
                        description: newRoomData.description,
                        accessibility: newRoomData.accessibility,
                        roomImg: newRoomData.imgFile,
                        mediaData: newRoomData.mediaData,
                        owner: this.$store.state.user.userId
                    };

                    api.createRoom(roomData).then(function(response) {
                        var newRoom = response.data;
                        var invitedIds = inviteResult.users.map(x => x.userId);

                        api.inviteMultipleChat(self.$store.state.user.userId, newRoom.roomId, invitedIds).then(function(response){
                            socialModule.joinRoom(newRoom.roomId);
                            self.currentRoomId = newRoom.roomId;
                            self.showChatInvite = false;
                            self.chatVisible = true;
                        }).catch(function(error){
                            self.showChatInvite = false;
                            //display error somehow
                            console.log(error);
                        })
                        
                    }).catch(function(error){
                        self.showChatInvite = false;
                        //display error somehow
                        console.log(error);
                    });
                }
                else{
                    
                    var invitedIds = inviteResult.users.map(x => x.userId);

                    api.inviteMultipleChat(this.$store.state.user.userId, inviteResult.room.roomId, invitedIds).then(function(response){
                        //display success somehow
                        self.showChatInvite = false;
                        console.log('Invite Success');
                    }).catch(function(error){
                        self.showChatInvite = false;
                        //display error somehow
                        console.log(error);
                    })
                }
            },
            isRequested: function(userId){
                return this.requestedFriends.includes(userId);
            },
            queryUpdated(){
                var self = this;
                clearTimeout(this.debounceTimer);

                this.debounceTimer = setTimeout(() => {
                    console.log('doing debouncer stuff');
                    if(!this.searchQuery){
                        self.showSearchResults = false;
                        return;
                    }
                    api.userSearch(this.$store.state.user.userId, self.searchQuery, 1).then((response) => {
                        self.requestedFriends = [];
                        if(response.data){
                            self.userResults = response.data;
                            self.showSearchResults = true;
                        }
                        
                    }).catch((e) => {
                        self.requestedFriends = [];
                        console.error(e);
                    })
                }, 300);
            },
            inviteToChat(invitedUser){
                this.invitedUser = invitedUser;
                this.showChatInvite = true;

            },
            addFriend(friendId){
                var self = this;
                api.requestFriend(this.$store.state.user.userId, friendId).then((response) => {
                    self.requestedFriends.push(friendId);
                })
                .catch(e => {
                    console.error(e);
                })
            }
        }
    }

</script>

<style scoped>
    .users-container{
        width:100%;
        height:100%;
        display:flex;
        flex-direction: column;
        gap: 1em;
        padding:1em;
    }

    .users-search-container{
        flex: 0 0 3em;
        display:flex;
        flex-direction: row;
        width: 100%;
        gap: .4em;
        margin-top: 2em;
    }

    .user-search-input{
        width:100%;
        border-radius: 5em;
        font-size: 1em;
        padding-left:1em;
        padding-right:1em;
    }

    .user-friends-container{
        flex: 1;
        width: 100%;
        display:flex;
        flex-direction: column;
    }

    .user-friends-title{
        width:100%;
        text-align: left;
        font-size: 1.3em;
        font-weight: bold;
        flex: 0 0 3em;
    }

    .user-friends-list{
        width:100%;
        flex: 1;
        display:flex;
        flex-direction: column;

    }

    .user-friends-item{
        width:100%;
        flex: 0 0 2em;
        padding:1em;
        display:flex;
        flex-direction: row;
        align-items: center;
        background-color: black;
        transition: all 200ms ease in ease-in-out;
        gap: 1em;
        box-sizing: border-box;
    }

    .user-friends-item:hover{

    }

    .user-friends-item-name{

        font-weight: bold;
        font-size: 1.2em;
    }

    .user-friends-item-avatar{
        border-radius: 100%;
        width: 2.5em;
        height: 2.5em;
        background-repeat:no-repeat;
        background-position: center;
        background-size:cover;
        cursor:pointer;

    }

    .user-friends-item-invite-btn{
        background-color: rgb(255, 255, 255, .1);
        transition: all 200ms ease in ease-in-out;
    }

    .user-friends-item{
        width:100%;
        flex: 0 0 2em;
        padding:1em;
        display:flex;
        flex-direction: row;
        align-items: center;
        background-color: black;
        transition: all 200ms ease in ease-in-out;
        gap: 1em;
        box-sizing: border-box;
    }

    .user-friends-item:hover{
        background-color: rgb(255, 255, 255, .1);
        transition: all 200ms ease in ease-in-out;
    }
    .users-results-container{
        flex: 1;
        width:100%;
        display:flex;
        flex-direction: column;
    }

    .users-results-item{
        width:100%;
        flex: 0 0 2em;
        padding:1em;
        display:flex;
        flex-direction: row;
        align-items: center;
        background-color: black;
        transition: all 200ms ease in ease-in-out;
        gap: 1em;
        box-sizing: border-box;
    }

    .users-results-item:hover{
        background-color: rgb(255, 255, 255, .1);
        transition: all 200ms ease in ease-in-out;
    }

    .user-results-item-avatar{
        border-radius: 100%;
        width: 2.5em;
        height: 2.5em;
        background-repeat:no-repeat;
        background-position: center;
        background-size:cover;
        cursor:pointer;
    }

    .user-results-item-name{
        font-weight: bold;
        font-size: 1.2em;
    }

    .user-results-add-btn{
        border-radius: .4em;
        padding: .4em;
        font-weight:bold;
        cursor:pointer;
        transition: all 100ms ease-in-out;
        background-color: rgb(255, 255, 255, .1)
    }

    .user-results-add-btn:hover{
        background-color:rgb(255, 255, 255, .3);
        transition: all 100ms ease-in-out;
    }

    .user-results-invite-btn{
        border-radius: .4em;
        padding: .4em;
        font-weight:bold;
        margin-left:auto;
        cursor:pointer;
        transition: all 100ms ease-in-out;
        background-color: rgb(255, 255, 255, .1)
    }

    .user-results-invite-btn:hover{
        background-color:rgb(255, 255, 255, .3);
        transition: all 100ms ease-in-out;
    }

    .user-friends-invite-btn{
        border-radius: .4em;
        padding: .4em;
        font-weight:bold;
        margin-left:auto;
        cursor:pointer;
        transition: all 100ms ease-in-out;
        background-color: rgb(255, 255, 255, .1)
    }

    .user-friends-invite-btn:hover{
        background-color:rgb(255, 255, 255, .3);
        transition: all 100ms ease-in-out;
    }

</style>