import { render, staticRenderFns } from "./ChatMusic.vue?vue&type=template&id=0197d903&scoped=true&"
import script from "./ChatMusic.vue?vue&type=script&lang=js&"
export * from "./ChatMusic.vue?vue&type=script&lang=js&"
import style0 from "./ChatMusic.vue?vue&type=style&index=0&id=0197d903&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0197d903",
  null
  
)

export default component.exports