<template>
    <div class="signin-container" >
        <div v-on:click="closeModal" class="close-btn">
            <i class="fa-solid fa-times-circle"></i>
        </div>
        <div class="signin-options">
            <div class="login-bar" :class="loggingIn ? 'selected' : ''" v-on:click="switchToLogIn">
                Log-In
            </div>
            <div class="signup-bar" :class="!loggingIn ? 'selected' : ''" v-on:click="switchToSignUp">
                Sign-Up
            </div>
            <div class="options-filler"></div>
        </div>
        
        <div class="signin-contents">
            <div class="login-contents" v-if="loggingIn">
                <div class="error-message">{{ logInErrorMessage }}</div>
                <div class="defi-login-btn defi-btn" v-on:click="loginWithWallet">Login with Wallet</div>
                <div id="googlebuttonlogin"></div> 
            </div>
            <div class="signup-contents" v-if="!loggingIn">
                <input v-model="displayName" type="text" @input="sanitizeDisplayName" placeholder="Display Name" class="signup-input"  maxlength="20"/>
                <div class="error-message">{{ signUpErrorMessage }}</div>
                <div class="defi-register-btn defi-btn" v-on:click="RegisterWithWallet">Register with Wallet</div>
                <div id="googlebuttonsignup"></div>
            </div>
        </div>
        
        
    </div>
</template>

<script>

import { jwtDecode } from "jwt-decode";
import {
  PhantomWalletAdapter
} from '@solana/wallet-adapter-wallets';

import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { useWallet } from 'solana-wallets-vue-2-jc';
import 'solana-wallets-vue-2-jc/styles.css';
import wallet from '../module/wallet';
import api from '../api/app';
import bs58 from 'bs58';

  

export default {
      name: "SignIn",
      data() {
        return {
         loggingIn: true,
         loadingUser: false,
         modalState:false,
         displayName: "",
         logInErrorMessage : "",
         signUpErrorMessage: "",
         usedWallet: useWallet
         
        };
      },
      methods:{
        sanitizeDisplayName(){
            this.displayName = this.displayName.replace(/[^a-zA-Z0-9]/g, "");
        },
        async loginWithWallet(){
            
        var self = this;
        
            
            if(!this.loadingUser){
                
                this.loadingUser = true;

                try{
                    
                    var wallet = self.$parent.$refs.walletConnector.walletStore;
                    var publicKey = wallet.publicKey.toString();

                    api.startDefiLogin(publicKey).then(async (startResponse) => {
                        const message = new TextEncoder().encode(startResponse.data);
                        const signedMessage = await wallet.wallet.signMessage(message);
                        var signature = bs58.encode(signedMessage);

                        if(signature){                        
                            var logindata = {
                                publicKey: self.$parent.$refs.walletConnector.walletStore.publicKey.toString(),
                                loginType: "defi",
                                signature: signature,
                                signedMessage: startResponse.data
                            };
                        
                            self.$store.dispatch('logindefi', logindata).then(function(response){
                                
                                if(response.data.status=="login_successful"){
                                    self.$store.dispatch('setAuthToken', response.data.authToken);
                                    self.$emit('close');
                                    self.loadingUser = false;
                                }
                                else if(response.data.status=="user_not_found"){
                                    self.logInErrorMessage = "User Not Found. Please Sign Up or switch to registered Wallet.";
                                    self.loadingUser = false;
                                }
                                else{
                                    self.logInErrorMessage = "Error Logging In";
                                    self.loadingUser = false;
                                }
                            });
                        }
                    }).catch((e) => {
                        console.error(e);
                    })
                    
                }
                catch(e){
                    console.error(e);
                }
            }

        },
        async RegisterWithWallet(){
            
        var self = this;
            
            if(!this.loadingUser){
                
                this.loadingUser = true;
                try{

                    var wallet = self.$parent.$refs.walletConnector.walletStore;
                    var publicKey = wallet.publicKey.toString();

                    api.startDefiLogin(publicKey).then(async (startResponse) => {
                        const message = new TextEncoder().encode(startResponse.data);
                        const signedMessage = await wallet.wallet.signMessage(message);
                        var signature = bs58.encode(signedMessage);

                        if(signature){
                            if(!self.displayName){
                                self.signUpErrorMessage = "Please enter a Display Name to sign up";
                                return;
                            }
                            
                            var signupdata = {
                                publicKey : self.$parent.$refs.walletConnector.walletStore.publicKey.toString(),
                                displayName : self.displayName,
                                userType : "regular",
                                loginType: "defi",
                                signature: signature,
                                signedMessage: startResponse.data
                            };
                            
                            self.$store.dispatch('registerdefi', signupdata).then(function(response){
                                
                                if(response.data.status=="signup_successful"){
                                        self.$store.dispatch('setAuthToken', response.data.authToken);
                                        self.$emit('close');
                                        self.loadingUser = false;
                                }
                                else if(response.data.status=="user_exists"){
                                    //TODO - just login and send back appropriate data
                                    self.signUpErrorMessage = "User Already Exists. Please Log In.";
                                    self.loadingUser = false;
                                }
                                else{
                                    self.signUpErrorMessage = "Error Signing Up";
                                    self.loadingUser = false;
                                }
                            });
                        }
                    }).catch((e) => {
                        console.error(e);
                    })
                }
                catch(e){
                    console.error(e);
                }
            }
        },
        connectWallet() {
            //initWallet(this.walletOptions);
        },
        closeModal(){
            this.$emit('close');
        },
        switchToLogIn(){
            this.loggingIn = true;
            window.setTimeout(function(){
                window.google.accounts.id.renderButton(document.getElementById("googlebuttonlogin"),
                {
                    theme: 'outline',
                    size: 'large',
                    text: 'signin_with',
                    shape: 'rectangular'
                });
            },
            500);
        },
        switchToSignUp(){   
            this.loggingIn = false;
            
            
            window.setTimeout(function(){
                window.google.accounts.id.renderButton(document.getElementById("googlebuttonsignup"),
            {
                theme: 'outline',
                size: 'large',
                text: 'signup_with',
                shape: 'rectangular'
            });
            }, 
            500)
            
        }


      }, 
      mounted: function(){
        var self = this;
        window.setTimeout(function(){
            var innerSelf = self; //I don't know why but this makes 'self' available below
            window.google.accounts.id.initialize({
                client_id: process.env.VUE_APP_GOOGLE_CLIENT,
                callback: (res, error) => {
                    
                    var credential = jwtDecode(res.credential);

                    if(!self.loggingIn){
                        if(!self.displayName){
                            self.signUpErrorMessage = "Please enter a Display Name to sign up";
                            return;
                        }
                        var signupdata = {
                            email : credential.email,
                            displayName : self.displayName,
                            userType : "regular",
                            loginType: "google"
                        };
                        
                        self.$store.dispatch('register', signupdata).then(function(response){
                            if(response.data.status=="signup_successful"){
                                
                                 self.$emit('close');
                            }
                            else if(response.data.status=="user_exists"){
                                self.signUpErrorMessage = "User Already Exists. Please Log In.";
                            }
                            else{
                                self.signUpErrorMessage = "Error Signing Up";
                            }
                        });
                        
                    }
                    else if(self.loggingIn){
                        var logindata = {
                            email: credential.email,
                            loginType: "google"
                        };
                        
                        self.$store.dispatch('login', logindata).then(function(response){
                            if(response.data.status=="login_successful"){
                                
                                self.$emit('close');
                            }
                            else if(response.data.status=="user_not_found"){
                                self.logInErrorMessage = "User Not Found. Please Sign Up.";
                            }
                            else{
                                self.logInErrorMessage = "Error Logging In";
                            }
                        });
                    }
                    
                },
                });

                window.google.accounts.id.renderButton(document.getElementById("googlebuttonlogin"),
                {
                    theme: 'outline',
                    size: 'large',
                    text: 'signin_with',
                    shape: 'rectangular'
                });
        }, 300);
        this.walletOptions = {
            wallets : [
                new PhantomWalletAdapter()
            ]
          };

    
         /*data-type="standard"
                    data-shape="rectangular"
                    data-theme="outline"
                    data-text="signin_with"
                    data-size="large"
                    data-logo_alignment="left"*/
      }
}
</script>

<style scoped>
    .signin-container{
        background-color:white;
        max-width: 250px;
        max-height:500px;
        color:black;
        display:flex;
        flex-direction: column;
        text-shadow: none;
    }

    .defi-btn{
        background-color:black;
        border: solid white 1px;
        color: white;
        font-size:1em;
        font-weight: bold;
        padding: .5em;
        border-radius: .5em;
    }

    .close-btn{
        position:absolute;
        right: .5em;
        top: .5em;
        cursor: pointer;
        display:none;
    }

    .signin-options{
        margin-top: 1em;
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    .login-bar{
        padding: .2em;
        cursor: pointer;
        border-top: 1px solid black;
        border-right: 1px solid black;
        border-left: 1px solid black;
        border-bottom: 2px solid black;
        transition: all 200ms ease-in-out;
        font-weight:normal;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    .login-bar.selected{
        cursor:default;
        border-top: 2px solid black;
        border-left: 2px solid black;
        border-bottom: 2px solid white;
        transition: all 200ms ease-in-out;
        font-weight:bold;
    }

    .signup-bar{
        padding: .2em;
        cursor: pointer;
        border-top: 1px solid black;
        border-right: 1px solid black;
        border-left: 1px solid black;
        border-bottom: 2px solid black;
        transition: all 200ms ease-in-out;
        font-weight:normal;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    .signup-bar.selected{
        cursor:default;
        border-top: 2px solid black;
        border-right: 2px solid black;
        border-bottom: 2px solid white;
        transition: all 200ms ease-in-out;
        font-weight:bold;
    }
    .options-filler{
        flex-grow: 1;
        border-bottom: 2px solid black;
    }
    .signin-contents{
        width: 100%;
        height: 100%;
    }

    .login-contents{
        width: 100%;
        height: 100%;
        padding: 1em;
        display: flex;
        flex-direction: column;
        gap : .5em;
        box-sizing: border-box;

    }

    .signup-contents{
        width:100%;
        height:100%;
        padding: 1em;
        display: flex;
        flex-direction: column;
        gap : .5em;
        box-sizing: border-box;
    }

    .signup-input{
        max-width: 100%;
        font-weight:bold;
        border-radius: .5em;
        padding: .3em;
    }
    #googlebuttonlogin{
        margin-top:auto;
        align-self: center;

    }
    #googlebuttonsignup{
        margin-top:1em;
        align-self: center;
    }
    .error-message{
        font-size: .5em;
        color: red;
        
    }
    
  @media only screen and (max-width: 768px) {
    .signin-container{
        height:100%;
        width:100%;
        max-height:100%;
        max-width:100%;
    }

    .close-btn{
        position:absolute;
        right: .5em;
        top: .5em;
        cursor: pointer;
        display: block;
        font-size: 1.5em;
    }
  }
</style>