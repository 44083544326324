import apiClient from './app';

export default {
  login(credentials) {
    return apiClient.login(credentials);
  },
  loginDefi(credentials) {
    return apiClient.loginDefi(credentials);
  }
};
