<template>
    <div class="conversation-container">
        <ChatInvite v-if="showChatInvite" @close="closeInvite" @submit="submitInvite" v-bind="{currentRoom: currentRoom}"></ChatInvite>
        <ChatMusic v-if="musicSelectionVisible" @submit="updateMusic" @close="closeChatMusic"></ChatMusic>
        <div class="conversation-header">
            <div class="conversation-displayname">{{ displayName }}</div>
            <div class="conversation-header-controls">
                <div class="media-display-info">
                    <div class="media-display-info-child">{{ roomMediaData.displayName }}</div>
                </div>

                <!--<div v-on:click="startAudio" class="play-btn">Play</div>-->
                <div v-if="userIsOwner" v-on:click="showInvite" class="invite-btn"><i class="fa-solid fa-user-plus"></i></div>
                <div v-if="roomAudio" class="audio-btn" >
                    <div v-on:click="showAudioOptions" class="audio-btn-cover"></div>
                    <div :class="{expanded:audioOptionsVisible}" class="audio-options-container">
                        <div class="audio-mute-container">
                            <div class="mute-text">Mute</div>
                            <input type="checkbox" v-model="audioMuted" @change="onMuteChange"/>
                        </div>
                        <input type="range" min="0" max="100" v-model="audioVolume" @change="onVolumeChange"/>
                        <div class="audio-option-btns">
                            <div v-on:click="saveAudioOptions" class="audio-save-btn">Save</div>
                            <div v-on:click="hideAudioOptions" class="audio-cancel-btn">Cancel</div>
                        </div>
                    </div>
                    <i class="fa-solid fa-volume-off"></i>
                </div>
                <div v-if="userIsOwner" class="music-btn" v-on:click="showMusicSelection"><i class="fa-solid fa-music"></i></div>
                <div class="exit-btn" v-on:click="closeConversation"><i class="fa-solid fa-angle-double-left"></i></div>
            </div>

        </div>
        <div class="visual-container">
            <div v-on:click="changeVideoPosition" v-if="mediaSource.includes('youtube')" class="vid-pos-control">
                <i class="fa-solid fa-exchange-alt"></i>
            </div>
            <iframe class="vid-frame" v-if="showVideoFrame && videoPosition=='top'" :src="'https://www.youtube.com/embed/' + youtubeKey" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen anonymous></iframe>
           <!-- <div :style="{  height: item.height, opacity: item.opacity }" :key="item.key" v-for="(item) in visualizerItems" class="visual-item"></div>-->
            <div v-if="!showVideoFrame" class="canvas-container">
                <div v-if="displayAudioPlay" class="audio-play-container">
                    <div v-on:click="playAudio" class="audio-play-btn">Continue Playing Audio</div>
                </div>
                <canvas class="v-canvas"></canvas>
                <input type="range" min="0" max="100" v-model="visualizerAmplitude" class="v-amplitude-input"/>
                <select class="visual-select" v-model="selectedVisual">
                    <option v-for="visual in visuals" :key="visual.value" v-bind:value="visual.value">
                        {{ visual.label }} 
                    </option>
                </select>
            </div>
        </div>
        <div class="conversation-body-holder">
            <!--<canvas class="v-canvas"></canvas>-->
            <div class="conversation-body">
                
                <div class="conversation-message" :class="'message-' + message.roomMessageId" v-for="message in visibleMessages" :key="message.createdAt">
                    <div v-on:click="goToProfile(message.userId)" class="message-owner-container">
                        <div class="message-owner-avatar" :style="{backgroundImage: `url(${avatarBaseUrl}${message.userId}.webp)`}"></div>
                        <div class="message-owner-label">{{ message.userDisplayName + " " }}</div>
                    </div>
                    <div class="message-text">
                        <div v-if="message.parentMessage" v-on:click="goToParent(message.parentMessage)" class="message-replying-to">
                            {{ "Replying to - " + getParentUser(message.parentMessage)  + " : " + getParentMessage(message.parentMessage) }}
                        </div>
                        {{ message.message }}
                        <div class="msg-text-btns" :class="{show:(treeMessage == message.roomMessageId || childrenMessage == message.roomMessageId)}">
                            <div v-on:click="startReply(message.roomMessageId)" class="message-reply-btn">
                                <i class="fa-solid fa-reply"></i>
                            </div>
                            <div v-on:click="toggleTree(message.roomMessageId)" class="message-tree-btn" :class="{enabled:(treeMessage == message.roomMessageId)}">
                                <i class="fa-solid fa-link"></i>
                            </div>
                            <div v-on:click="toggleChildren(message.roomMessageId)" class="message-children-btn" :class="{enabled:(childrenMessage == message.roomMessageId)}">
                                <i class="fa-solid fa-sitemap"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <iframe class="vid-frame" v-if="showVideoFrame && videoPosition=='side'" :src="'https://www.youtube.com/embed/' + youtubeKey" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen anonymous></iframe>
        </div>
        <div class="conversation-controls">
            <div v-if="replying" class="reply-label">
                <div class="reply-label-text">{{  "Replying to - " + getParentUser(parentMessage)  + " : " + getParentMessage(parentMessage)}}</div>
                <div v-on:click="cancelReply" class="cancel-reply-btn">Cancel</div>
            </div>
            <input v-model="currentMessage" v-on:keyup.enter="sendMessage" class="message-input"/>
            <div v-on:click="sendMessage" class="send-message-btn">Send</div>
        </div>
      
    </div>
  </template>
  
  <script>
  import { mapState } from 'vuex';
  import ChatMusic from '../../components/ChatMusic';
import { onUnmounted } from 'vue';
import ChatInvite from '../../components/socialmodals/ChatInvite';
import visualize from '../../module/visualize';
import { Visualizer, createStarburst } from '../../module/visualizer';
import isModule from '../../module/social';
import api from '../../api/app';

import Meyda from 'meyda';
  
  export default {
      components: {
        ChatMusic,
        ChatInvite
      },
      props:{
        sModule: Object
      },
    data() {
      return {
        socialModule: null,
        id: null,
        localRooms: {},
        roomMessages : [],
        displayName: "",
        user : {},
        currentMessage : "",
        description: "",
        currentRoom: {},
        replying: false,
        parentMessage: "",

        treeEnabled: false,
        treeMessages: [],
        treeMessage: "",

        childrenEnabled: false,
        childrenMessages: [],
        childrenMessage: "",

        showVideoFrame: false,

        videoPosition: 'top',

        displayAudioPlay: false,
        musicSelectionVisible: false,
        roomAudio: null,
        roomAudioAnalyzer: null,
        roomMediaData: {},
        audioMuted: false,
        audioVolume: 50,
        audioOptionsVisible:false,
        visualizerItems: [],
        mediaElementSource: null,
        audioContext: null,
        canvas: {},
        ctx: {},
        visualizerAmplitude: 5,
        visualRotation: 0,
        avatarBaseUrl: process.env.VUE_APP_AVATAR_BASE_URL,
        showChatInvite: false,
        visualizer: {},
        vLocation: "",
        vLocationOptions: [
            { value: "header", label: "Top"},
            { value: "background", label: "Chat Background"},
            { value: "fullscreen", label: "Full Screen"}
        ],
        selectedVisual:"starburst",
        visuals: [
            {
                value: "starburst",
                label: "Star Burst"
            },
            {
                value: "chromabars",
                label: "Chroma Bars"
            }
        ],
        animateVFrame: true
      };
    },
    created(){
        this.id = this.$route.params.id;
        
    },
    destroyed(){
        this.clearRoomAudio();
    },
    beforeDestroy(){        
        this.clearRoomAudio();
    },
    mounted: function(){
        this.user = this.$store.state.user;
        var self = this;
        if(this.sModule){
            this.socialModule = this.sModule;
        }
        else{
            this.socialModule = isModule;
        }
        
        if(!this.socialModule.initiated){
            this.socialModule.init();
        } 
        window.setTimeout(function(){
            self.socialModule.joinRoom(this.id);
        }, 50);
        


        if(this.roomMediaData.source && this.roomMediaData.source == 'radiobrowser'){
            this.canvas = document.querySelector('.v-canvas');
            //this.ctx = this.canvas.getContext('2d');
            //this.ctx.globalCompositeOperation='destination-over';

            this.canvas.width = this.canvas.offsetWidth;
            this.canvas.height = this.canvas.offsetHeight;
            
            window.addEventListener('resize', event => {
                self.canvas.width = self.canvas.offsetWidth;
                self.canvas.height = self.canvas.offsetHeight;
            }, true);

        }
        for(var i = 0; i < 200; i++){
            this.visualizerItems.push({
                key: ('vItem' + i),
                height : 0,
                opacity: 1
            });
        }

        

        if(this.$store.state.user.metadata && this.$store.state.user.metadata.audioVolume){
            var volumeValue = this.$store.state.user.metadata.audioVolume
            this.setVolume(volumeValue);
        }

        
        if(this.$store.state.rooms && this.$store.state.rooms[this.id]){
            var room = this.$store.state.rooms[this.id];
            this.currentRoom = room;
            this.displayName = room.displayName;
            this.description = room.description;
            this.roomMessages = room.messages;
            this.roomMediaData = room.mediaData;
            
            this.startRoomMedia();
        }

        

    },
    methods: {
        changeVideoPosition(){
            if(this.videoPosition == "top"){
                this.videoPosition = "side";
            }
            else{
                this.videoPosition = "top";
            }
        },
        setupCanvas(){
            this.canvas = document.querySelector('.v-canvas');
            //this.ctx = this.canvas.getContext('2d');
            //this.ctx.globalCompositeOperation='destination-over';

            this.canvas.width = this.canvas.offsetWidth;
            this.canvas.height = this.canvas.offsetHeight;
            var self = this;
            window.addEventListener('resize', event => {
                self.canvas.width = self.canvas.offsetWidth;
                self.canvas.height = self.canvas.offsetHeight;
            }, true);
        },
        clearRoomAudio(){
            if(this.roomAudio){
                this.roomAudioAnalyzer.stop();
                this.roomAudio.pause();
                this.roomAudio.src = '';
                this.mediaElementSource.disconnect();
                this.visualizer = null;
            }
        },
        goToProfile(id){
            this.$router.push({name: 'social_profile', params : {id: id}}).catch(()=>{});
        },
        cancelReply(){
          this.parentMessage = "";
          this.replying = false;  
        },
        getParentMessage(id){
            var parent = this.roomMessages.find((x) => x.roomMessageId == id);

            if(parent){
                return parent.message;
            }

            return "";
        },
        getParentUser(id){
            var parent = this.roomMessages.find((x) => x.roomMessageId == id);

            if(parent){
                return parent.userDisplayName;
            }

            return "";
        },
        goToParent(id){
            console.log(id);

            var elem = document.querySelector('.message-' + id);

            if(elem){
                elem.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        },  
        toggleTree(id){           
            var self = this;

            if(id == this.treeMessage){
                this.treeMessage = "";
                this.treeMessages = [];
                this.treeEnabled = false;
                window.setTimeout(() => {
                    self.scrollToBottom();
                }, 30);
            }
            else {
                this.childrenMessage = "";
                this.childrenMessages = [];
                this.childrenEnabled = false;

                var msgs = [];
                var treeMessages = this.getTreeMessages(id, msgs);
               
                treeMessages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

                this.treeMessage = id;
                this.treeMessages = treeMessages;
                this.treeEnabled = true;
            }
        },
        
        getTreeMessages(msgId, msgs){
            var msg = this.roomMessages.find((m) => m.roomMessageId == msgId);
            msgs.push(msg)
            if(msg.parentMessage){
              
                return this.getTreeMessages(msg.parentMessage, msgs);
            }
            else{
                return msgs;
            }
        },
        toggleChildren(id){           
            var self = this;

            if(id == this.childrenMessage){
                this.childrenMessage = "";
                this.childrenMessages = [];
                this.childrenEnabled = false;
                window.setTimeout(() => {
                    self.scrollToBottom();
                }, 30);
            }
            else {
                this.treeMessage = "";
                this.treeMessags = [];
                this.treeEnabled = false;

                var msgs = [];
                var childrenMessages = this.getChildrenMessages(id);
               
                childrenMessages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
                var msg = this.roomMessages.find((x) => x.roomMessageId == id);

                childrenMessages.unshift(msg);
                
                this.childrenMessage = id;
                this.childrenMessages = childrenMessages;
                this.childrenEnabled = true;
            }
        },
        getChildrenMessages(id){            
            var msgs = this.roomMessages.filter((x) => x.parentMessage == id);
            return msgs;
        },
        startReply(id){
            this.parentMessage = id;
            this.replying = true;
        },
        stopReply(){
            this.parentMessage = "",
            this.replying = false;
        },
        showInvite(){
            this.showChatInvite = true;
        },
        closeInvite(){
            this.showChatInvite = false;
        },
        submitInvite(inviteResult){
            var self = this;
           
            if(this.userIsOwner){
                var invitedIds = inviteResult.users.map(x => x.userId);

                api.inviteMultipleChat(this.$store.state.user.userId, this.currentRoom.roomId, invitedIds).then(function(response){
                    //display success somehow
                    self.showChatInvite = false;
                    console.log('Invite Success');
                }).catch(function(error){
                    self.showChatInvite = false;
                    //display error somehow
                    console.log(error);
                })
            }
            
        },
        playAudio(){
            this.displayAudioPlay = false;
            this.startRoomMedia();
        },
        startAnalyzer(){
            var self = this;
            

            if (this.audioContext) {
        this.audioContext.close();
        this.audioContext = null;
    }
    if (this.mediaElementSource) {
        this.mediaElementSource.disconnect();
        this.mediaElementSource = null;
    }
    if (this.roomAudioAnalyzer) {
        this.roomAudioAnalyzer.stop();
        this.roomAudioAnalyzer = null;
    }

    // Create a new audio context and source
    this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
    this.mediaElementSource = this.audioContext.createMediaElementSource(this.roomAudio);
    this.mediaElementSource.connect(this.audioContext.destination);

            
            
           // var worker = new Worker('../../worker');
            this.roomAudioAnalyzer = Meyda.createMeydaAnalyzer({
                audioContext: this.audioContext,
                source: this.mediaElementSource,
                bufferSize: 512,
                featureExtractors: ['rms', 'spectralCentroid', 'spectralFlatness', 'amplitudeSpectrum', 'powerSpectrum', 'buffer', 'chroma', 'mfcc'],
                callback: (features) =>{
                    if(self.visualizer)
                        self.visualizer.update(features, self.visualizerAmplitude);
/*
                    //-------this.ctx.fillStyle = "rgba(0, 0, 0, 0.05)"; // frame blending
                    //this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
                    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
                    //CIRCLES VISUALIZER
                    //index/8 looks awesome
                    var centerX = this.canvas.width / 2;
                    var centerY = this.canvas.height / 2;
                    var largeRadius = .2 * this.visualizerAmplitude;
                    var smallRadius = 1;

                    //ROTATING 5 STAR
                    features.amplitudeSpectrum.slice(0,200).forEach((value, index) =>{
                        //added value/index calc to rotation for spiral 
                        //var angle = (index / 5) * 2 * Math.PI + ((this.visualRotation-(value * (index+1))) * Math.PI / 180); //index denominator controls # of partical population lines
                        var angle = ((Math.ceil(index/40)*40)/200) * 2 * Math.PI + ((this.visualRotation) * Math.PI / 180); //index denominator controls # of partical population lines
                        //split frequency levels
                        var x = centerX + (largeRadius * value * (index + 1)) * Math.cos(angle);
                        var y = centerY + (largeRadius * value * (index + 1)) * Math.sin(angle);

                        //max calc
                        var percentOfMax = (value * (index + 1) * 2)/(index+1);
                        var colorFactor = 150 * percentOfMax;
                        //max calc

                        //this.ctx.filter = 'blur(3px)';
                        this.ctx.beginPath();
                        this.ctx.arc(x, y, (smallRadius), 0, 2 * Math.PI);
                        this.ctx.fillStyle = `rgb(${0 + colorFactor},${105 + colorFactor},${255})`;
                        this.ctx.fill();
                        this.ctx.closePath();
                    })
                    this.ctx.filter = `blur(${(largeRadius) * features.rms * 512}px)`;
                    this.ctx.beginPath();
                    this.ctx.arc(centerX, centerY, ((largeRadius) * features.rms * 512), 0, 2 * Math.PI);
                    this.ctx.fillStyle = 'rgb(0, 100, 255)';
                    this.ctx.fill();
                    this.ctx.closePath();
                    this.ctx.filter = 'none';
                    


                    this.visualRotation+=.5;
*/
                 

                    /*if(this.animateVFrame){
                        if(this.selectedVisual == "starburst"){
                            visualize.starburst(this.canvas, this.ctx, features, this.visualizerAmplitude, this.visualRotation);
                        }
                        else if(this.selectedVisual == "chromabars"){
                            visualize.chroma(this.canvas, this.ctx, features, this.visualizerAmplitude);
                        }

                        this.animateVFrame = false;
                        window.setTimeout(() => {
                            self.animateVFrame = true;
                        }, 30);
                    }*/

                    
                    
                    //SIDEBAR VISUALIZER
                    //needs a ceiling for bar height so it doesn't exceed canvas width
                    
  /*                  this.ctx.fillStyle = "rgba(0, 0, 0, 0.08)"; // Black with low opacity
                    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
                    //this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

                    const barWidth = this.canvas.height / 180;

                    features.amplitudeSpectrum.slice(0, 180).forEach((value, index) => {
                        var barHeight = (index+1) * 50 * features.rms * value * this.visualizerAmplitude;
                        if(barHeight > this.canvas.width){
                            barHeight = this.canvas.width;
                        }
                        const y = index * barWidth;

                        const grad=this.ctx.createLinearGradient(0,0, 800,0); //1500*features.rms
                        grad.addColorStop(0, `rgb(0, ${isNaN(features.spectralCentroid) ? 0 : (features.spectralCentroid *2)}, 255, 1)`);
                        //grad.addColorStop(.5, "orange");
                        //grad.addColorStop(.8, "orange");
                        //grad.addColorStop(.5, "rgb(0, 0, 255, .3)");
                        grad.addColorStop(barHeight/this.canvas.width, "rgb(180, 180, 255)");

                        this.ctx.fillStyle = grad; // Fade effect
                        this.ctx.fillRect(0, y, barHeight, barWidth - 2);
                    })

                    var centerX = this.canvas.width / 2;
                    var centerY = this.canvas.height / 2;
                    var largeRadius = 10 * this.visualizerAmplitude;

                    this.ctx.beginPath();
                    this.ctx.arc(centerX, centerY, (largeRadius), 0, 2 * Math.PI);
                    this.ctx.fillStyle = `rgb(0, ${isNaN(features.spectralCentroid) ? 0 : (features.spectralCentroid)}, 255, ${isNaN(features.spectralFlatness) ? 0 : (features.spectralFlatness)})`;
                    this.ctx.fill();
                    this.ctx.closePath();
*/
                    

/*

                    //BAR VISUALIZER
                    console.log('rms : ' + features.rms);
                    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

                    // Bar dimensions
                    const barWidth = this.canvas.width / features.amplitudeSpectrum.length;

                    // Draw each bar
                    features.amplitudeSpectrum.forEach((value, index) => {
                        const barHeight = (index+1) * 50 * features.rms * value * this.visualizerAmplitude + 1; // Adjust scaling factor as needed
                        const x = index * barWidth;

                        this.ctx.fillStyle = `rgba(255, 255, 255)`; // Fade effect
                        this.ctx.fillRect(x, this.canvas.height - barHeight, barWidth - 2, barHeight); // -2 for spacing between bars
                    });

                    */
                    
                    //CHROMA TEST
                    
                    //this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
                    /*this.ctx.fillStyle = "rgba(0, 0, 0, 0.05)"; // Black with low opacity
                    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

                    // Bar dimensions
                    const barWidth = this.canvas.width / 36;

                    // Draw each bar
                    features.chroma.forEach((value, index) => {
                        const barHeight =  10* (value + (30*features.rms)) * value * value * value * this.visualizerAmplitude; // Adjust scaling factor as needed
                        //var barHeight = value * this.visualizerAmplitude;
                        
                        const x = index * barWidth;

                        const grad=this.ctx.createLinearGradient(0,0, 0, 2000);
                        grad.addColorStop(0, `rgb(255, 0, 0, 1)`);
                        //grad.addColorStop(.5, "orange");
                        //grad.addColorStop(.8, "orange");
                        //grad.addColorStop(.5, "rgb(0, 0, 255, .3)");
                        grad.addColorStop(barHeight/this.canvas.width, "rgb(255, 100, 150, 1)");
                        this.ctx.fillStyle = grad; // Fade effect
                        this.ctx.fillRect(x, this.canvas.height - barHeight, barWidth - 2, barHeight); // -2 for spacing between bars
                    });*/

                 /*   worker.postMessage({
                        features: features,

                    });

                    worker.onmessage = function(e){
                        e.data.forEach((item, index)=>{
                            self.visualizerItems[index].height = item.height;
                        })
                    };*/
                    //console.log('audio features:', features);
                }
            });

            function animate() {
                requestAnimationFrame(animate);
                if(self.visualizer)
                    self.visualizer.render();
            }
            animate();

            this.roomAudioAnalyzer.start();
        },
        setVolume(value){
            
            this.audioVolume = value; 
            if(value == 0){
                this.audioMuted = true;
            }
            else{
                this.audioMuted = false;
            }
            if(this.roomAudio){
                this.roomAudio.volume = value/100;
            }
        },
        onVolumeChange(item){
            var newValue = item.currentTarget._value;
            if(newValue == 0){
                this.audioMuted = true;
            }
            else{
                this.audioMuted = false;
            }
            this.roomAudio.volume = newValue/100;
        },
        onMuteChange(item){
            var newValue = item.currentTarget.value;
            if(newValue == 'on'){
                this.audioVolume = 0;
            }
            this.roomAudio.volume = this.audioVolume/100;
        },
        hideAudioOptions(){
            this.audioOptionsVisible = false;
        },
        showAudioOptions(){
            this.audioOptionsVisible = true;
        },
        saveAudioOptions(){
            //social call
            this.socialModule.updateUserMeta('audioVolume', parseInt(this.audioVolume));
            this.audioOptionsVisible = false;



        },
        animateMediaDisplay(){
            const parentDiv = document.querySelector('.media-display-info');
            const childText = document.querySelector('.media-display-info-child');
              // Get widths of parent and child
            const parentWidth = parentDiv.offsetWidth;
            const childWidth = childText.offsetWidth;

            // Initial position and speed (pixels per frame)
            let position = parentWidth; // Start off-screen to the right
            const speed = 1; // Adjust this for desired speed

            function animate() {
                // Update position
                position -= speed;

                // Reset position when fully off-screen to the left
                if (position < -childWidth) {
                position = parentWidth;
                }

                // Apply the new position
                childText.style.left = `${position}px`;

                // Request the next frame
                requestAnimationFrame(animate);
            }

            // Start the animation
            animate();
        },
        startAudio(){
            this.startRoomMedia();
        },
        startRoomMedia(){
            var self = this;
            
            if(this.roomMediaData && this.roomMediaData.source == 'radiobrowser'){
                this.showVideoFrame=false;
                window.setTimeout(function(){
                    self.setupCanvas();
                    if (self.roomAudio) {
                        self.roomAudio.pause();
                        self.roomAudio.src = '';
                        self.roomAudio.load();
                    }

                    if (self.roomAudioAnalyzer) {
                        self.roomAudioAnalyzer.stop();
                        self.roomAudioAnalyzer = null;
                    }

                    if (self.mediaElementSource) {
                        self.mediaElementSource.disconnect();
                        self.mediaElementSource = null;
                    }

                    if (self.audioContext) {
                        self.audioContext.close();
                        self.audioContext = null;
                    }
                    
                    if(!self.visualizer || !self.visualizer.render){
                        self.visualizer = new Visualizer(self.canvas);
                        self.visualizer.registerVisualizer('starburst', createStarburst);
                        self.visualizer.switchVisualizer('starburst');
                    }

                    // Create new Audio element and set it up
                    self.roomAudio = new Audio(self.roomMediaData.streamUrl);
                    self.roomAudio.volume = self.audioVolume / 100;
                    self.roomAudio.crossOrigin = 'anonymous';

                            
                    self.startAnalyzer();
                    self.roomAudio.play().then(() => {
                        self.animateMediaDisplay();
                        console.log('audio is playing');
                    })
                    .catch((e) => {
                        
                        console.error(e);
                        if(e.code == 0){
                            self.displayAudioPlay = true;
                        }
                    })
                }, 30);

                
            }

            else if(this.roomMediaData && this.roomMediaData.source == 'youtube'){
                this.clearRoomAudio();
                this.showVideoFrame = true;
            }
        },
        updateMusic(mediaData){
            this.socialModule.updateRoomMusic(this.currentRoom.roomId, mediaData);
            /*
            this.musicSelectionVisible = false;
            this.roomAudio = new Audio(station.streamUrl);

            this.roomAudio.play().then(() => {
                console.log('audio is playing');
            })
            .catch((e) => {
                console.error(e);
            })*/

        },
        scrollToBottom(){
            var messageBody = document.querySelector('.conversation-body');
            messageBody.scrollTop = messageBody.scrollHeight;
            
        },
        closeChatMusic(){
            this.musicSelectionVisible = false;
        },
        showMusicSelection(){
            this.musicSelectionVisible = true;
        },
        closeConversation(){
            this.$router.push({name: 'social_rooms'}).catch(()=>{});            
        },
        sendMessage(){
            if(this.currentMessage.trim()){
                this.socialModule.sendMessage({
                    message: this.currentMessage,
                    roomId : this.id,
                    parentMessage : this.parentMessage
                });
                this.currentMessage = "";
                this.cancelReply();
            }

        },
        checkIfLoaded(){
            var self = this;
            this.$nextTick(() =>{
                self.scrollToBottom();
            })
        }
    },
    computed: {
      ...mapState(['rooms']),
      userIsOwner: function(){
        return this.user.userId == this.currentRoom.owner;
      },
      visibleMessages: function(){
        if(this.treeEnabled){
            return this.treeMessages;
        }
        if(this.childrenEnabled){
            return this.childrenMessages;
        }
        return this.roomMessages;
      },
      mediaSource: function(){
        if(this.roomMediaData){
            return this.roomMediaData.source || "";
        }
        return "";
      },
      youtubeKey: function(){
        if(this.roomMediaData)
        {
            return this.roomMediaData.url || "";
        }
        return "";
      }
    },
    watch: {
        rooms : {
            handler() {
                console.log('rooms update');
                if(this.$store.state.rooms && this.$store.state.rooms[this.id]){
                    var room = this.$store.state.rooms[this.id];
                    this.currentRoom = room;
                    this.roomMessages = room.messages;
                    this.displayName = room.displayName;
                    
                    if(this.roomMediaData != room.mediaData){
                        this.roomMediaData = room.mediaData;
                        this.startRoomMedia();
                    }
                    //TODO - if currentmessage in room.messages, clear currentmessage here. stop input until this confirmation. saves the
                    //current message, in case of backend error and they don't want to have to retype
                    console.log('new message');
                }
            },
            deep: true
        },
        roomMessages(newValue){
            this.checkIfLoaded();
        }
    },
  };
  </script>

  <style scoped>
  .vid-frame{
    top:0px;
    left:0px;
    z-index:1;
    height:100%;
    aspect-ratio: 16/9;
  }

  .conversation-body > .vid-frame{
    position:absolute;
    right:0px;
    top:0px;
    height:100%;
    aspect-ratio: 16/9;
    z-index: 1;

  }
    .conversation-header{
        width: 100%;
        flex: 0 0 3em;
        display:flex;
        flex-direction: row;
        align-items: center;
        color:white;

    }

    .canvas-container{
        width:100%;
        height:100%;
        position:relative;
        display:flex;
        flex-direction:row;
        align-items: end;
        overflow:hidden;
        margin-bottom:0;
    }
    .visual-container{
        width:90%;
        flex: 0 0 20%;
        display:flex;
        flex-direction:row;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        overflow:hidden;
        position:relative;
        background-color:black;
    }

    .v-canvas{
        width:100%;
        height:100%;
        display:block;
        position:absolute;
        top:0;
        left:0;
        z-index:0;
    }

    .visual-item{
        background-color:white;
        border-radius: 10em;
        width: .2em;
        height:.2em;
        bottom: 0;
    }

    .conversation-displayname{
        font-weight:bold;
        margin-right:auto;
        margin-left: 1em;

    }

    .conversation-header-controls{
        display:flex;
        flex-direction: row;
        align-items: center;
        margin-right:1em;
        margin-left:auto;
        gap:.5em;
        height:1em;
        flex:1;
    }

    .exit-btn{

        padding: .2em;
        align-items: center;
        justify-content: center;
        display:flex;
        border-radius: .5em;
        cursor: pointer;
        font-size: 1.5em;
        transition: all 100ms ease-in-out;
    }

    .exit-btn:hover{
        background-color: rgb(255, 255, 255, .2);
        transition: all 100ms ease-in-out;
    }

    .audio-play-container{
        position: absolute;
        width:100%;
        height:100%;
        background-color:black;
        top:0px;
        left:0px;
        display:flex;
        align-items: center;
        justify-content: center;
        z-index:6;
    }

    .audio-play-btn{
        font-weight:bold;
        font-size: .8em;
        color:white;
        background-color:black;
        cursor:pointer;
        border-radius: .5em;
        padding: .4em;
        transition: all 100ms ease-in-out;
        border: 1px solid white;

    }

    .audio-play-btn:hover{
        background-color:#444;
        transition: all 100ms ease-in-out;
    }

    .music-btn{
        padding: .2em;
        align-items: center;
        justify-content: center;
        display:flex;
        border-radius: .5em;
        cursor: pointer;
        font-size: 1.5em;
        transition: all 100ms ease-in-out;
    }

    .music-btn:hover{
        background-color: rgb(255, 255, 255, .2);
        transition: all 100ms ease-in-out;
    }

    .audio-btn-cover{
        position:absolute;
        top:0;
        left:0;
        height:100%;
        width:100%;
    }

    .audio-btn{
        padding: .2em .4em;
        align-items: center;
        justify-content: center;
        display:flex;
        border-radius: .5em;
        cursor: pointer;
        font-size: 1.5em;
        transition: all 100ms ease-in-out;
        position:relative;
    }

    .invite-btn:hover{
        background-color: rgb(255, 255, 255, .2);
        transition: all 100ms ease-in-out;
    }

    .invite-btn{
        padding: .2em .4em;
        align-items: center;
        justify-content: center;
        display:flex;
        border-radius: .5em;
        cursor: pointer;
        font-size: 1.5em;
        transition: all 100ms ease-in-out;
        position:relative;
    }

    .audio-btn:hover{
        background-color: rgb(255, 255, 255, .2);
        transition: all 100ms ease-in-out;
    }

    .conversation-controls{
        width: 100%;
        flex: 0 0 4em;
        display:flex;
        flex-direction: row;
        padding: 1em;
        gap: 1em;
        box-sizing: border-box;
        align-items: center;
        position:relative;
        padding-top: 3em;
    }
    .conversation-body-holder{
        width:100%;
        flex:1;
        background-color:black;
        position:relative;
        display:flex;
        box-sizing: border-box;
        overflow:hidden;
    }
    .conversation-body{
        width:100%;
        flex: 1;
        border-radius: .8em;
        padding: 1em;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 1em;
        overflow-y: scroll;
        z-index:1;
        position:relative;
    }
    .conversation-container{
        width: 100%;
        height: 100%;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position:absolute;
        z-index: 2;
        background-color:black;
    }

    .message-input{
        box-shadow: 0px 0px 3px rgb(255, 255, 255, .3);
        border-radius: .3em;
        height: 100%;
        flex: 1;
        background: black;
        color:white;
    }
    .send-message-btn{
        border: 1px solid white;
        border-radius: .5em;
        padding: .8em;
        font-weight:bold;
        cursor:pointer;
        color:white;
        background-color:black;
        transition: all 100ms ease-in-out;
        flex: 0 0 6em;
        height:100%;
        box-sizing: border-box;
    }

    .reply-label-text{
        max-width: 30%;
        overflow:hidden;
        text-overflow: ellipsis;
        text-wrap:nowrap;
    }

    .reply-label{
        width: 100%;
        height: 2em;
        background-color: black;
        color:white;
        font-weight:bold;
        position:absolute;
        top:0px;
        left:0px;
        display:flex;
        flex-direction:row;
        align-items:center;
        gap:2em;
    }

    .send-message-btn:hover{
        background-color:#333;
        transition: all 100ms ease-in-out
    }

    .play-btn{
        border: 1px solid white;
        border-radius: .5em;
        padding: .8em;
        font-weight:bold;
        cursor:pointer;
        color:white;
        background-color:black;
        transition: all 100ms ease-in-out;
        box-sizing: border-box;
    }



    .play-btn:hover{
        background-color:#333;
        transition: all 100ms ease-in-out
    }

    .conversation-message{
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 2em;
        z-index:1;
        position:relative;
    }

    .message-owner-container{
        flex : 0 0 5em;
        display:flex;
        flex-direction: column;
        align-items:center;
        gap: .1em;
        cursor:pointer;
    }

    .message-text{
        position:relative;
        flex: 1;
        text-align: left;
        max-width: 30em;
        border-radius: .6em;
        padding: 1em;
        padding-top:2.5em;
        color:white;
        word-wrap:break-word;
        background-color: #111;
        box-shadow: 2px 2px 1px rgb(255, 255, 255, .4) inset;
    }

    .cancel-reply-btn{
        font-weight:bold;
        padding: .2em;
        border-radius: .5em;
        border: 1px solid white;
        color:white;
        cursor:pointer;
        background-color:black;
        transition: all 200ms ease-in-out;
        display:flex;
        align-items:center;
        justify-content: center;

    }

    .cancel-reply-btn:hover{
        background-color:#444;
        transition: all 200ms ease-in-out;
    }

    .msg-text-btns{
        padding-top:.5em;
        padding-right:.5em;
        position:absolute;
        right:0px;
        top:0px;
        height:2em;
        width:auto;
        display:flex;
        flex-direction: row;
        gap: .5em;
        opacity:0;
        transition: all 200ms ease-in-out;
    }

    .message-text:hover > .msg-text-btns{
        opacity:1;
        transition: all 200ms ease-in-out;
    }

    .msg-text-btns.show{
        opacity:1;
        transition: all 200ms ease-in-out;
    }

    .message-replying-to{
        position:absolute;
        top:0px;
        left:0px;
        max-width:50%;
        background-color: rgb(0, 30, 0);
        display:inline;
        flex-direction:row;
        overflow:hidden;
        text-overflow:ellipsis;
        text-wrap:nowrap;
        align-items: center;;
        padding: .4em;
        border-radius:.4em;
        font-size:.8em;
        font-weight:bold;
        cursor:pointer;
    }
    .message-reply-btn{
        border-radius: .2em;
        width:1.4em;
        height:1.4em;
        display:flex;
        align-items: center;
        justify-content: center;
        border: 1px solid white;
        color: white;
        cursor: pointer;
        transition: all 200ms ease-in-out;
    }

    .message-reply-btn:hover{
        background-color:#444;
        transition: all 200ms ease-in-out;
    }

    .message-tree-btn{
        border-radius: .2em;
        width:1.4em;
        height:1.4em;
        display:flex;
        align-items: center;
        justify-content: center;
        border: 1px solid white;
        color: white;
        cursor: pointer;
        transition: all 200ms ease-in-out;
    }

    .message-tree-btn:hover{
        background-color:#444;
        transition: all 200ms ease-in-out;
    }

    .message-tree-btn.enabled{
        border: 1px solid white;
        color: white;
        cursor: pointer;
        background-color:rgb(0, 0, 150);
        transition: all 200ms ease-in-out;
    }

    .message-tree-btn.enabled:hover{
        background-color:#444;
        background-color:rgb(100, 100, 150);
        transition: all 200ms ease-in-out;
    }

    .message-children-btn{
        border-radius: .2em;
        width:1.4em;
        height:1.4em;
        display:flex;
        align-items: center;
        justify-content: center;
        border: 1px solid white;
        color: white;
        cursor: pointer;
        transition: all 200ms ease-in-out;
    }

    .message-children-btn:hover{
        background-color:#444;
        transition: all 200ms ease-in-out;
    }

    .message-children-btn.enabled{
        border: 1px solid white;
        color: white;
        cursor: pointer;
        background-color:rgb(0, 0, 150);
        transition: all 200ms ease-in-out;
    }

    .message-children-btn.enabled:hover{
        background-color:#444;
        background-color:rgb(100, 100, 150);
        transition: all 200ms ease-in-out;
    }


    .message-owner-label{
        font-weight: bold;
        font-size: 1em;
        color: white;
        
    }

    .message-owner-avatar{
        width: 2em;
        height:2em;
        background-repeat:no-repeat;
        background-position: center;
        background-size:cover;
        border-radius: 100%;
    }

    .media-display-info{
        position:relative;
        height:100%;
        margin-right:auto;
        margin-left:1em;
        width: 15em;
        overflow:hidden;
    }

    .media-display-info-child{
        position:absolute;
        left:100%;
        color: green;
        font-weight:bold;
        font-style: italic;
        text-wrap:nowrap;
    }

    .audio-options-container{
        position:absolute;
        background-color: rgb(0,0,0,.7);
        max-height: 0%;
        width:10em;
        top: 100%;
        right:0px;
        padding:0;
        height:auto;
        display:flex;
        flex-direction:column;
        gap: .4em;
        justify-content: center;
        align-items: center;
        overflow:hidden;
        box-sizing: border-box;
        min-height:0;
        margin-top:1em;
    }

    .audio-options-container.expanded{
        max-height:100%;
        padding:1em;
        min-height:7em;
        z-index: 4;
        border: 1px solid rgb(255,255,255,.3);
    }

    .audio-mute-container{
        display:flex;
        flex-direction:row;
        font-size:.7em;
        gap: .4em;
        align-items:center;
        width:100%;
        
    }

    .audio-option-btns{
        display: flex;
        flex-direction:row;
        justify-content: center;
        align-items: center;
        gap:1em;
        margin-top:1em;
    }

    .audio-save-btn{
        font-weight: bold;
        font-size: .8em;
        padding: .4em;
        border-radius: .4em;
        border: 1px solid #555;
        cursor:pointer;
        transition: all 100ms ease-in-out;

    }

    .audio-save-btn:hover{
        background-color: rgb(255, 255, 255, .3);
        transition: all 100ms ease-in-out;
    }

    .audio-cancel-btn{
        font-weight: bold;
        font-size: .8em;
        padding: .4em;
        border-radius: .4em;
        border: 1px solid #555;
        cursor:pointer;
        transition: all 100ms ease-in-out;
    }

    .audio-cancel-btn:hover{
        background-color: rgb(255, 255, 255, .3);
        transition: all 100ms ease-in-out;
    }

    .vid-pos-control{
        font-size: 1em;
        padding: .7em;
        color:white;
        position:absolute;
        bottom:.1em;
        right:2em;
        z-index:2;
        display:none;
        border-radius:.4em;
        cursor:pointer;
        border: 1px solid white;
        background-color:black;
        transition: all 100ms ease-in-out;
    }

    .visual-container:hover > .vid-pos-control{
        display:block;
    }

    .visual-container:hover > .vid-pos-control:hover{
        display:block;
        background-color:#444;
        transition: all 100ms ease-in-out;
    }

    .v-amplitude-input{
        z-index: 2;
        position:absolute;
        bottom: 2em;
        right: 2em;
        display:none;
    }

    .canvas-container:hover > .v-amplitude-input{
        display: block;
    }

    .visual-select{
        z-index: 2;
        position: absolute;
        bottom: 4em;
        right: 2em;
        display:none;
    }

    .canvas-container:hover > .visual-select{
        display:block;
    }

  </style>