import apiClient from './app';

export default {
  signup(user) {
    return apiClient.signup(user);
  },
  signupDefi(user) {
    return apiClient.signupDefi(user);
  }
};
