import { faCropSimple } from "@fortawesome/free-solid-svg-icons";
import * as THREE from "three";

class Visualizer {
    constructor(canvas) {
        this.canvas = canvas;
        this.renderer = new THREE.WebGLRenderer({ canvas });
        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(75, canvas.width / canvas.height, 0.1, 1000);
        this.camera.position.z = .5;

        this.visualizerMethods = {};
        this.currentVisualizer = null;
    }

    registerVisualizer(name, method) {
        this.visualizerMethods[name] = method;
    }

    switchVisualizer(name, options) {
        if (this.visualizerMethods[name]) {
            if (this.currentVisualizer && this.currentVisualizer.cleanup) {
                this.currentVisualizer.cleanup();
            }
            this.currentVisualizer = this.visualizerMethods[name](this.scene, this.camera, this.renderer, options);
        } else {
            console.error(`Visualizer "${name}" not found.`);
        }
    }

    update(features, vAmp) {
        if (this.currentVisualizer && this.currentVisualizer.update) {
            this.currentVisualizer.update(features, vAmp);
        }
    }

    render() {
        if (this.currentVisualizer && this.currentVisualizer.render) {
            this.currentVisualizer.render();
        } else {
            this.renderer.render(this.scene, this.camera);
        }
    }
}

// Starburst visualizer method
function createStarburst(scene, camera, renderer, options = {}) {
    const uniforms = {
        amplitudeSpectrum: { value: new Float32Array(200) },
        visualRotation: { value: 0.0 },
        largeRadius: { value: 0.2 },
        right: { value: 0 },
        yellow: { value: 0.0 },
        red: { value: 0.0 }
    };

    const starburstMaterial = new THREE.ShaderMaterial({
        uniforms,
        vertexShader: `
            attribute float size;
            attribute float index;
            uniform float right;
            uniform float largeRadius;
            uniform float amplitudeSpectrum[200];
            uniform float visualRotation;
            varying vec3 vColor;

            void main() {
            
                float value = amplitudeSpectrum[int(index)];
                float angle = ((ceil(index / 40.0) * 40.0) / 200.0) * 2.0 * 3.141592653589793 +
                              (visualRotation - (value * (index + 1.0))) * 3.141592653589793 / 180.0;
                              
                float percentOfMax = (value * (index + 1.0) * 2.0) / (index + 1.0);
                float colorFactor = 150.0 * percentOfMax;
                vColor = vec3(0.0 + colorFactor / 255.0, 105.0 / 255.0 + colorFactor / 255.0, 1.0);

                float x = (largeRadius * value * (index + 1.0)) * cos(angle);
                float y = (largeRadius * value * (index + 1.0)) * sin(angle);
                gl_PointSize = size;
                gl_Position = projectionMatrix * modelViewMatrix * vec4(x, y, 0.0, 1.0);
            }
        `,
        fragmentShader: `
        
            uniform float red;
            uniform float yellow;

            void main() {

                float dist = length(gl_PointCoord - vec2(0.5));
                if (dist > 0.5) discard; // Circle points
                gl_FragColor = vec4(red, yellow, 1.0, 0.7); //vec4(vColor, 1.0); // Red color
            }
        `,
        /*vertexShader: `
            attribute float index;
            uniform float visualRotation;
            uniform float largeRadius;
            uniform float amplitudeSpectrum[200];
            varying vec3 vColor;

            void main() {
                float value = amplitudeSpectrum[int(index)];
                float angle = ((ceil(index / 40.0) * 40.0) / 200.0) * 2.0 * 3.141592653589793 +
                              visualRotation * 3.141592653589793 / 180.0;

                float x = (largeRadius * value * (index + 1.0)) * cos(angle);
                float y = (largeRadius * value * (index + 1.0)) * sin(angle);

                float percentOfMax = (value * (index + 1.0) * 2.0) / (index + 1.0);
                float colorFactor = 150.0 * percentOfMax;
                vColor = vec3(0.0 + colorFactor / 255.0, 105.0 / 255.0 + colorFactor / 255.0, 1.0);

                vec4 modelPosition = vec4(x, y, 0.0, 1.0);
                gl_Position = projectionMatrix * modelViewMatrix * modelPosition;
                gl_PointSize = 200.0;
            }
        `,
        fragmentShader: `
            varying vec3 vColor;
            

            void main() {
                float dist = length(gl_PointCoord - vec2(0.5));
                if (dist > 0.5) discard;
                gl_FragColor = vec4(vColor, 1.0);
            }
        `,*/
    });

    //const geometry = new THREE.BufferGeometry();
   
    const indices = new Float32Array(200);
    //for (let i = 0; i < 200; i++) indices[i] = i;
  //  geometry.setAttribute("index", new THREE.BufferAttribute(indices, 1));

   // const starburst = new THREE.Points(geometry, starburstMaterial);
   const pointCount = 200; // Number of points
    const positions = new Float32Array(pointCount * 3); // x, y, z for each point
    const sizes = new Float32Array(pointCount); // Sizes for each point

    for (let i = 0; i < pointCount; i++) {
        positions[i * 3] = Math.random() * 2 - 1; // Random x
        positions[i * 3 + 1] = Math.random() * 2 - 1; // Random y
        positions[i * 3 + 2] = 0; // z
        sizes[i] = 2.5 * Math.random(); // Point size
        indices[i] = i;
    }

    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    geometry.setAttribute('size', new THREE.BufferAttribute(sizes, 1));
    geometry.setAttribute("index", new THREE.BufferAttribute(indices, 1));

    const starburst = new THREE.Points(geometry, starburstMaterial);
    
    scene.add(starburst);

    return {
        update(features, vAmp) {
            uniforms.amplitudeSpectrum.value.set(features.amplitudeSpectrum.slice(0, 200));
            uniforms.largeRadius.value = features.rms * vAmp * .2;
            uniforms.visualRotation.value += 0.5;
            uniforms.right.value = Math.random() * 10;
            uniforms.red.value = Math.random();
            uniforms.yellow.value = Math.random();
        },
        render() {
            renderer.render(scene, camera);
        },
        cleanup() {
            scene.remove(starburst);
        },
    };
}

// Export the Visualizer class and the Starburst visualizer
export { Visualizer, createStarburst };
